import React, { useState } from 'react'
import styled, { css } from 'styled-components'

import Container from './container'
import { marginMixin } from '../theme'
import { onMobile, onDesktop, isMobile } from '@src/utils/responsive'
import { Grid, Cell } from './grid'

import { kebabCase, get, uniqueId } from 'lodash-es'
import { animated } from '../utils/animated'

const SectionColumn = styled.div`
    width: 100%;
    ${onDesktop`
    width: calc(${({ width }) => width * 100}% - 14.1rem/2);
  `}
`

const SectionContainer = styled(Container)`
    ${({ useColumns }) =>
        useColumns
            ? css`
                  display: flex;
                  justify-content: space-between;
                  flex-wrap: wrap;
              `
            : ``}
`

export const Section = animated(styled(
    React.forwardRef(({ id, className, children, fullwidth, columns = 1, style }, ref) => {
        const [persistentId] = useState(id ? id : uniqueId('section-'))

        return (
            <section id={persistentId} className={'section ' + className} style={style} ref={ref}>
                <SectionContainer
                    fullwidth={fullwidth}
                    useColumns={columns > 1 || columns === true}
                >
                    {columns <= 1 || columns === true
                        ? children
                        : children.map((child, key) => (
                              <SectionColumn width={1 / columns} key={key}>
                                  {child}
                              </SectionColumn>
                          ))}
                </SectionContainer>
            </section>
        )
    })
)`
    ${({ animation }) => {
        return animation == 'fade-in-up'
            ? css`
                  opacity: 0;
                  transform: translateY(5vh);
                  transition: opacity 0.3s ease, transform 0.3s ease;

                  &.animated--appeared {
                      opacity: 1;
                      transform: translateY(0px);
                  }
              `
            : css``
    }}
    ${marginMixin}
`)

export default Section
