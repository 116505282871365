import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { join, map, entries } from 'lodash-es'

const VimeoVideoProviderIframe = styled.iframe`
    border: 0;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;

    line-height: 0;
`

export const VimeoVideoProvider = React.memo(
    React.forwardRef((props, ref) => {
        const {
            id,
            className,
            background,
            vimeoId,
            cover,
            fillParent = false,
            autoplay = false,
            controls = true,
            looped = false,
            muted = false,
            title = false,
            byLine = false,
            portrait = false,
            allowFullscreen = false,
            aspectRatio,
        } = props

        const src = `https://player.vimeo.com/video/${vimeoId}`

        const parameters = background
            ? {
                  background: 1,
                  autoplay: 1,
                  muted: 1,
                  mute: 1,
                  loop: 1,
                  byline: 0,
                  title: 0,
                  controls: 0,
              }
            : {
                  controls: controls ? 0 : 1,
                  autoplay: autoplay ? 1 : 0,
                  muted: muted ? 1 : 0,
                  mute: mute ? 1 : 0,
                  loop: looped ? 1 : 0,
                  title: title ? 1 : 0,
                  byline: byLine ? 1 : 0,
                  portrait: portrait ? 1 : 0,
              }

        const queryParameters = join(
            map(entries(parameters), ([k, v]) => `${k}=${v}`),
            '&'
        )

        return (
            <VimeoVideoProviderIframe
                id={id}
                ref={ref}
                className={className}
                src={`${src}?${queryParameters}`}
                frameBorder="0"
                allow={['autoplay', 'fullscreen'].filter(Boolean).join(';')}
                allowFullScreen={allowFullscreen}
                {...{ fillParent, aspectRatio }}
            />
        )
    })
)
