import React, { useEffect, useState } from 'react'

import { throttle, noop, bind, get, includes } from 'lodash-es'

import { TransitionState } from 'gatsby-plugin-transition-link'

import TRANSITIONS, { DEFAULT_TRANSITION } from './transitions'
import styled, { css } from 'styled-components'
import classNames from 'classnames'

const debug = false ? bind(console.log, console, '[withTransitionState]') : noop

const transitionWaittime = 0.6
const transitionDuration = 0.6

const slideTransition = (initialPosition) => css`
    .page-wrapper:after {
        content: '';
        display: block;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 99999999;

        transform: translate3d(${initialPosition == 'left' ? -100 : 100}%, 0%, 1px);

        background: white;
        pointer-events: none;
    }

    .page-wrapper.schema-black:after {
        background: black;
    }

    &.entering {
        .page-wrapper {
            & > * {
                transition: opacity ${transitionDuration}s ease ${transitionWaittime}s;
                opacity: 1;
            }

            &:before {
                transition: opacity 0s ease ${transitionWaittime}s;
                opacity: 1;
            }

            &:after {
                transition: opacity ${transitionDuration}s ease ${transitionWaittime}s,
                    transform 0.8s ease;
                transform: translate3d(0%, 0%, 1px);
                opacity: 1;
            }
        }
    }

    &.entered {
        .page-wrapper {
            & > *,
            &:before {
                opacity: 1;
            }

            &:after {
                transition: opacity ${transitionDuration}s ease;
                transform: translate3d(0%, 0%, 0);
                opacity: 0;
            }
        }
    }

    &.exiting {
        .page-wrapper {
            & > * {
                transition: opacity ${transitionDuration}s ease 0s;
                opacity: 0;
            }

            &:before {
                transition: opacity 0s ease ${transitionWaittime}s;
                opacity: 0;
            }

            &:after {
                transition: opacity ${transitionDuration}s ease,
                    transform ${transitionDuration}s ease ${transitionWaittime}s;
                transform: translate3d(${initialPosition == 'left' ? -100 : 100}%, 0%, 0);
                opacity: 1;
            }
        }
    }

    &.exited {
        .page-wrapper {
            & > *,
            &:before {
                opacity: 0;
            }

            &:after {
                transform: translate3d(${initialPosition == 'left' ? -100 : 100}%, 0%, 0);
                opacity: 1;
            }
        }
    }
`

const Transition = styled.div`
    &.transition--slide-from-right {
        ${slideTransition('right')}
    }

    &.transition--slide-from-left {
        ${slideTransition('left')}
    }
`

export const withTransitionState = (Component) => (props) => {
    const { useTransitions = true, transition, location } = props

    const handleScroll = throttle((scrollY) => {
        if (scrollY) {
            const scrollEvent = new CustomEvent('page:scroll', {
                detail: {
                    scrollY,
                },
            })
            //debug('Dispatch', { event: scrollEvent.detail })
            window.dispatchEvent(scrollEvent)
        } else {
            //debug('Dispatch cancelled: null target')
        }
    }, 50)

    return true ? (
        <TransitionState>
            {({ transitionStatus, mount, current, ...otherTransitionProps }) => {
                const state = current.state || {}
                const { enteringPage, exitingPage } = state

                const role = current.state?.path == location.pathname ? 'entering' : 'exiting'

                const thisPage = role == 'entering' ? enteringPage : exitingPage

                debug(`${exitingPage} => ${enteringPage}`)
                debug(role, 'Page rendered', props.title || 'Home', location.pathname, {
                    transitionState: transitionStatus,

                    mount,
                    schema: props.schema,
                    state,
                    ...otherTransitionProps,
                })

                let transition = 'transition--no-transition'
                if (false) {
                    if (thisPage == 'project') {
                        transition = 'transition--slide-from-left'
                    } else {
                        transition = 'transition--slide-from-right'
                    }
                }

                if (role == 'entering') {
                } else {
                    if (location.pathname == '/' || enteringPage != 'home') {
                        transition = 'transition--no-transition'
                    }
                }

                return (
                    <Transition
                        className={classNames(transition, transitionStatus)}
                        onScroll={(event) => handleScroll(event.target && event.target.scrollTop)}
                    >
                        <Component
                            {...props}
                            render={includes(['entered', 'exiting'], transitionStatus)}
                        />
                    </Transition>
                )
            }}
        </TransitionState>
    ) : (
        <Component {...props} />
    )
}
