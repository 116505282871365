import React, { useState, useRef, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { Image } from '../../components/Image'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { get, pick, omit, isBoolean } from 'lodash-es'
import VimeoPlayer from '@vimeo/player'

import { onDesktop, onMobile, isMobile } from '@src/utils/responsive'
import TransitionLink from '../../components/transition-link'
import { Heading2, Subtitle, Heading3, Title } from '../../components/Text'
import { Media } from '../../components/Media'
import { createResponsiveComponent } from '../../utils'
import { desktopQuery, mobileQuery } from '../../utils/responsive'
import { em } from '../../theme'

const PortfolioProjectText = styled(Heading3)`
    text-align: center;
    margin-bottom: 1.5rem;

    @media ${desktopQuery} {
    }

    transition: opacity 0.15s ease;

    opacity: ${({ visible }) => (isBoolean(visible) ? (visible ? 1 : 0) : 1)};
`

const PortfolioProjectCategories = styled(Subtitle)`
    display: block;
    max-width: 70%;
    margin: auto;

    span {
        white-space: nowrap;

        &:after {
            content: ',';
            margin-right: 0.2em;
        }

        &:last-child:after {
            content: '';
        }
    }

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;

    @media ${mobileQuery} {
        margin-top: 0.3rem;
        line-height: ${em(19 / 14)};
    }

    @media ${desktopQuery} {
        overflow: hidden;
        min-height: 6.5rem;
        margin-top: 0.2rem;
    }
`

const PortfolioProjectHoverSlider = styled(Slider)`
    position: absolute !important;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;

    pointer-events: none;
    //display: none !important;

    .slick-list,
    .slick-track {
        position: absolute !important;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
`

const PortfolioImage = styled(Media)`
    position: absolute !important;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    & > :first-child {
        padding-bottom: 0 !important;
    }
`

const PortfolioHoverMedia = styled(Media)`
    position: absolute !important;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
`

const PortfolioHoverVideo = styled((props) => {
    const { visible, handle } = props
    const [player, setPlayer] = useState(null)
    const videoRef = useRef()

    useEffect(() => {
        if (!player && videoRef.current) {
            try {
                play
                const _player = new VimeoPlayer(videoRef.current.id)
                setPlayer(_player)
            } catch (e) {
                console.error('Error initializing hover video', e)
            }
        }
    }, [player, setPlayer, videoRef.current])

    useEffect(() => {
        try {
            if (player) {
                if (visible) {
                    player.play()
                } else {
                    player.pause()
                }
            }
        } catch (e) {
            console.error('Error playing/pausing video', e)
        }
    }, [visible, player])

    return (
        <iframe
            id={`${handle}-hover-video-${props.vimeoId}`}
            ref={videoRef}
            className={props.className}
            src={`https://player.vimeo.com/video/${props.vimeoId}?background=1&controls=0&autoplay=0&loop=1&mute=1`}
            frameBorder="0"
            allow="autoplay; fullscreen"
            allowFullScreen
        />
    )
})`
    border: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: black;
    pointer-events: none;
`

const PortfolioImageWrapper = styled.div`
    position: relative;
    overflow: hidden;

    padding-bottom: ${((1 / (16 / 9)) * 100).toFixed(2)}% !important;

    ${({ orientation }) =>
        orientation == 'portrait'
            ? css`
                  @media ${desktopQuery} {
                      padding-bottom: ${((1 / (11.5 / 16)) * 100).toFixed(2)}% !important;
                  }
              `
            : css``}
`

const PortfolioLink = styled(TransitionLink)`
    display: block;
    line-height: 0;

    &:hover .gatsby-image-wrapper {
        opacity: 1;
    }
`

export const PortfolioHover = styled((props) => {
    const { thumbnailHoverMediaList = [], thumbnailHoverMediaVimeoId, isHovering, handle } = props

    if (thumbnailHoverMediaVimeoId) {
        return (
            <div className={props.className}>
                <PortfolioHoverVideo
                    handle={handle}
                    vimeoId={thumbnailHoverMediaVimeoId}
                    visible={isHovering}
                />
            </div>
        )
    } else if (thumbnailHoverMediaList && thumbnailHoverMediaList.length == 1) {
        return (
            <div className={props.className}>
                <PortfolioHoverMedia {...thumbnailHoverMediaList[0]} fillParent cover />
            </div>
        )
    } else if (thumbnailHoverMediaList && thumbnailHoverMediaList.length >= 2) {
        return (
            <div className={props.className}>
                <PortfolioProjectHoverSlider
                    {...{
                        fade: true,
                        slidesToShow: 1,
                        arrows: false,
                        infinite: thumbnailHoverMediaList.length >= 2,
                        autoplay: thumbnailHoverMediaList.length >= 2,
                        autoplaySpeed: 3000,
                    }}
                >
                    {thumbnailHoverMediaList.map((media, key) => (
                        <div key={key}>
                            <PortfolioHoverMedia {...media} />
                        </div>
                    ))}
                </PortfolioProjectHoverSlider>
            </div>
        )
    } else {
        return null
    }
})`
    position: absolute !important;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;

    transition: opacity 0.15s ease;
    opacity: 0;

    & > * {
        //display: none !important;
    }

    &:hover {
        opacity: 1;
        & > * {
            //display: block !important;
        }
    }
`

export const PortfolioProject = createResponsiveComponent(styled(
    React.forwardRef(
        (
            {
                className,
                coverImage,
                thumbnailImagePortrait,
                thumbnailImageLandscape,
                thumbnailHoverMediaList = [],
                thumbnailHoverMediaVimeoId,
                thumbnailHoverMediaListPortrait = [],
                thumbnailHoverMediaVimeoIdPortrait,
                comingSoon,
                handle,
                title,
                categories,
                orientation,
                entering,
                entered,
                exiting,
                exited,
                empty,
                isMobile,
                isDesktop,
                ...props
            },
            ref
        ) => {
            const [isHovering, setIsHovering] = useState(false)

            const renderedCategories = empty ? [] : categories || []

            const thumbnailImage =
                (orientation == 'portrait' && isDesktop
                    ? thumbnailImagePortrait
                    : thumbnailImageLandscape) || coverImage

            return (
                <div ref={ref} className={className}>
                    <PortfolioLink to={comingSoon ? '#' : `/projects/${handle}`}>
                        <PortfolioImageWrapper
                            orientation={orientation}
                            onMouseEnter={() => setIsHovering(true)}
                            onMouseLeave={() => setIsHovering(false)}
                        >
                            {!empty && (
                                <PortfolioImage
                                    {...thumbnailImage}
                                    player={false}
                                    loop={true}
                                    autoplay={true}
                                    controls={false}
                                    fillParent
                                    forceVisible={exiting || exited}
                                />
                            )}
                            {!(exiting || exited || empty) && (
                                <PortfolioHover
                                    {...{
                                        handle,
                                        thumbnailHoverMediaList:
                                            orientation == 'portrait' && isDesktop
                                                ? thumbnailHoverMediaListPortrait
                                                : thumbnailHoverMediaList,
                                        thumbnailHoverMediaVimeoId:
                                            orientation == 'portrait' && isDesktop
                                                ? thumbnailHoverMediaVimeoIdPortrait
                                                : thumbnailHoverMediaVimeoId,
                                        isHovering,
                                    }}
                                    fillParent
                                />
                            )}
                        </PortfolioImageWrapper>
                    </PortfolioLink>
                    <PortfolioProjectText visible={entered}>
                        <PortfolioLink
                            className="title"
                            style={{
                                letterSpacing: '0.01em',
                                lineHeight: '1.3em',
                            }}
                            to={comingSoon ? '#' : `/projects/${handle}`}
                        >
                            {title}
                        </PortfolioLink>
                        <PortfolioProjectCategories>
                            {comingSoon
                                ? 'Coming Soon'
                                : renderedCategories.map(({ title }, key) => (
                                      <span key={key}>{title}</span>
                                  ))}
                        </PortfolioProjectCategories>
                    </PortfolioProjectText>
                </div>
            )
        }
    )
)`
    position: relative;

    margin: 0 0 0.8rem 0;

    ${onMobile`
        margin-bottom: 4.4rem;
    `}

    a:hover {
        text-decoration: none;
    }

    &:hover .title {
        text-decoration: underline;
    }

    ${({ empty }) =>
        empty
            ? css`
                  & > * {
                      opacity: 0;
                  }
              `
            : ``}

    ${({ exited, exiting, entering, entered, animationState }) =>
        exiting || exited
            ? css`
                  position: absolute;
                  top: 0;
                  left: 0;
                  right: 0;
                  z-index: 1;
                  pointer-events: none;
              `
            : entered
            ? css``
            : css``}

    ${({ exited, exiting, entering, entered, animationState }) =>
        ({
            entering: css`
                opacity: 0;
                transform: translateY(20px);
            `,
            entered: css`
                transition: opacity 0.3s ease 0.3s, transform 0.3s ease 0.3s;
                opacity: 1;
                transform: translateY(0px);
            `,
            exiting: css`
                opacity: 1;
                transform: translateY(0px);
            `,
            exited: css`
                transition: opacity 0.3s ease, transform 0.3s ease;
                opacity: 0;
                transform: translateY(-20px);
            `,
        }[animationState])}
`)
