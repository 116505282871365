import { camelCase, defaults } from 'lodash'
import React from 'react'
import { useEffect } from 'react'
import { useRef } from 'react'
import styled, { css } from 'styled-components'

import { MP4VideoProvider } from './VideoProvider/MP4VideoProvider'
import { VimeoVideoProvider } from './VideoProvider/VimeoVideoProvider'

const PlaceholderText = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 80%;
`

const VideoRoot = styled.div`
    position: relative;
    width: 100%;
    height: auto;
    line-height: 0;

    ${({ fillParent }) =>
        fillParent &&
        css`
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            height: 100%;
        `}

    ${({ aspectRatio, fillParent }) =>
        !fillParent && aspectRatio && `padding-bottom: ${((1 / aspectRatio) * 100).toFixed(2)}%;`}
`

const getAssetProps = ({ description = '' } = {}) =>
    description
        .split(/\n+/)
        .filter((line) => line.indexOf('=') >= 1)
        .reduce((props, line) => {
            const parts = line.split('=')
            const key = camelCase((parts[0] || '').trim())
            let value = (parts[1] || '').trim()

            if (value.indexOf(':') >= 1) {
                try {
                    const [width, height] = value.split(':').map(parseFloat)
                    value = width / height
                } catch (e) {
                    value = 16 / 9
                }
            } else if (value === 'true' || value === 'yes') {
                value = true
            } else if (value === 'false' || value === 'no') {
                value = false
            }

            return { ...props, [key]: value }
        }, {})

export const Video = React.forwardRef(
    (
        {
            player = false,
            controls = player,
            autoplay = !player,
            loop = !player,
            description,
            file: { contentType = 'unknown', fileName, url, ...file } = {},
            cover,
            ...props
        },
        ref
    ) => {
        const assetProps = getAssetProps({ description })

        const provider = contentType.includes('video')
            ? 'mp4'
            : contentType.includes('text/html')
            ? 'vimeo'
            : 'unknown'

        const { aspectRatio, fillParent } = defaults(props, getAssetProps({ description }), {
            aspectRatio: provider == 'vimeo' ? 16 / 9 : false,
            fillParent: false,
        })

        return (
            <VideoRoot {...props} {...{ aspectRatio, fillParent }} ref={ref}>
                {provider == 'mp4' ? (
                    <MP4VideoProvider
                        {...{
                            controls,
                            autoplay,
                            muted: autoplay,
                            file: { contentType, fileName, url },
                            loop,
                            fillParent: fillParent || aspectRatio,
                            cover,
                        }}
                    />
                ) : provider == 'vimeo' ? (
                    <VimeoVideoProvider
                        {...{
                            vimeoId: fileName,
                            background: !controls,
                            autoplay,
                            muted: autoplay,
                            looped: loop,
                            allowFullscreen: controls,
                            aspectRatio: aspectRatio,
                            fillParent: true,
                            cover,
                        }}
                    />
                ) : (
                    <PlaceholderText>Video could not be loaded</PlaceholderText>
                )}
            </VideoRoot>
        )
    }
)
