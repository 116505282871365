import React from 'react'
import styled from 'styled-components'
import { animated } from '../utils/animated'

import Container from './container'

export const Divider = animated(
    styled(
        React.forwardRef(({ alwaysVisible, ...props }, ref) => (
            <Container {...props} ref={ref}>
                <hr />
            </Container>
        ))
    )`
        ${({ alwaysVisible }) => (alwaysVisible ? '' : 'display: none;')}

        hr {
            margin: 0;
        }

        .schema-black & {
            display: block;

            hr {
                border-width: 1px;
                border-color: var(--color-white);
            }
        }

        transform-origin: left center 0px;
        opacity: 0;
        transition: transform 0.8s cubic-bezier(0.165, 0.84, 0.44, 1) 0.3s, opacity 0.8s ease 0.3s;
        transform: scaleX(0.01);
        &.animated--appeared {
            opacity: 1;
            transform: scaleX(1);
        }
    `
)

export default Divider
