
import React from 'react'


const ArrowTopIcon = (props) => 
   <svg 
      xmlns="http://www.w3.org/2000/svg" 
      version="1.1"
      viewBox="0 0 5.5562499 6.8791669"
      height="26"
      width="21"
      {...props}
   >
      <g transform="translate(0,-290.12081)">
         <g transform="matrix(0.99661055,0,0,0.97460743,-41.488425,225.11441)">
            <rect
               width="0.80180854"
               height="3.9422255"
               x="78.571739"
               y="15.756474"
               transform="rotate(45)" />
            <rect
               width="0.80180854"
               height="3.9422255"
               x="15.756474"
               y="-82.513962"
               transform="rotate(135)" />
            <rect
               width="0.8018074"
               height="5.9622679"
               x="-44.825375"
               y="-73.758514"
               transform="scale(-1)" />
         </g>
      </g>
</svg>
   

export default ArrowTopIcon