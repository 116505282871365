import React, { useState, useEffect } from 'react'
import { PortfolioProject } from './PortfolioProject'

import styled, { css } from 'styled-components'
import { onDesktop } from '@src/utils/responsive'
import { animated } from '@src/utils/animated'

import { isEmpty, zip, pick, uniqueId } from 'lodash-es'
import { mobileQuery } from '../../utils/responsive'

class Group {
    constructor(variant = 'triad') {
        this.variant = variant
        this.items = []
        this.mirrored = false
        this.index = null

        this.leftColumnItems = null
        this.rightColumnItems = null
        this.mirrored = false
    }

    addProject(project, key) {
        this.items.push({ key, project, orientation: 'landscape' })
        return this
    }

    get length() {
        return this.items.length
    }

    getLayout() {
        const getEmptyItem = () => ({
            project: {
                handle: `empty-${uniqueId()}`,
                empty: true,
            },
            orientation: 'landscape',
        })

        if (!(this.leftColumnItems || this.rightColumnItems)) {
            const leftColumnItems = []
            const rightColumnItems = []

            const mirrored = this.index % 2 == 1 && this.variant == 'triad'

            if (this.items[0]) {
                const item = this.items[0]
                item.orientation = this.variant == 'triad' ? 'portrait' : 'landscape'
                leftColumnItems.push(item)
            } else {
                if (this.variant == 'triad') {
                    leftColumnItems.push(getEmptyItem())
                }
            }

            if (this.items[1]) {
                rightColumnItems.push(this.items[1])
            } else {
                if (this.variant == 'triad') {
                    rightColumnItems.push(getEmptyItem())
                }
            }

            if (this.items[2]) {
                rightColumnItems.push(this.items[2])
            } else {
                if (this.variant == 'triad') {
                    rightColumnItems.push(getEmptyItem())
                }
            }

            if (this.length > 3) {
                this.items
                    .slice(3)
                    .forEach((item, index) =>
                        index % 2 == 0 ? leftColumnItems.push(item) : rightColumnItems.push(item)
                    )
            }

            this.mirrored = mirrored
            this.leftColumnItems = leftColumnItems
            this.rightColumnItems = rightColumnItems
        }

        return {
            ...pick(this, 'mirrored', 'leftColumnItems', 'rightColumnItems', 'variant'),
            key: `${this.index}`,
        }
    }
}

const buildGroups = (projects) => {
    const groups = []
    let groupKey = 0
    const addGroup = (group) => {
        group.index = groupKey++
        group.key = group.index
        groups.push(group)
    }
    let itemKey = 0
    let currentGroup = new Group()
    projects.forEach((project, index) => {
        if (project.featuredProject) {
            const g = new Group('featured')
            g.addProject(project, itemKey++)
            addGroup(g)
        } else {
            currentGroup.addProject(project, itemKey++)
            if (currentGroup.length == 3) {
                currentGroup.variant = 'triad'
                addGroup(currentGroup)
                currentGroup = new Group()
            }
        }
    })

    if (currentGroup.length >= 1) {
        addGroup(currentGroup)
    }

    return groups
}

const PortfolioLayoutRoot = styled.div`
    ${onDesktop`
    `}
`

const PortfolioColumn = styled.div`
    display: flex;
    flex-direction: column;

    ${onDesktop`
        justify-content: space-between;
        width: calc(50% - 14.4rem / 2);
    `}
`

const PortfolioGroup = styled(
    React.forwardRef(({ variant, leftColumnItems, rightColumnItems, mirrored, ...props }, ref) => {
        return (
            <div ref={ref} {...props}>
                <PortfolioColumn>
                    {leftColumnItems.map((item, key) => (
                        <PortfolioItem {...item} key={key} />
                    ))}
                </PortfolioColumn>

                <PortfolioColumn>
                    {rightColumnItems.map((item, key) => (
                        <PortfolioItem {...item} key={key} />
                    ))}
                </PortfolioColumn>
            </div>
        )
    })
)`
    display: flex;

    justify-content: space-between;
    align-content: stretch;

    width: 100%;
    max-width: 100%;

    flex-direction: column;

    ${onDesktop`
        flex-direction: ${({ mirrored }) => (mirrored ? 'row-reverse' : 'row')};
        margin-bottom: 5rem;
    `}

    &:last-child {
        margin-bottom: 0;
    }
`

export const PortfolioItem = animated(styled(
    React.forwardRef(({ project, orientation, ...props }, ref) => {
        const [previousProject, setPreviousProject] = useState(null)
        const [currentProject, setCurrentProject] = useState({ ...project, orientation })

        const [currentProjectState, setCurrentProjectState] = useState('entered')
        const [previousProjectState, setPreviousProjectState] = useState('exiting')

        useEffect(() => {
            if (!currentProject) {
                setCurrentProject({ ...project, orientation })
                setCurrentProjectState('entering')
                setTimeout(() => {
                    setCurrentProjectState('entered')
                }, 10)
            } else if (project.handle != currentProject.handle) {
                setPreviousProject(currentProject)
                setCurrentProject({ ...project, orientation })
                setCurrentProjectState('entering')
                setPreviousProjectState('exiting')

                setTimeout(() => {
                    setCurrentProjectState('entered')
                    setPreviousProjectState('exited')
                }, 10)
            }
        }, [project])

        return (
            <div {...props} ref={ref}>
                <PortfolioProject
                    {...currentProject}
                    key={currentProject.handle}
                    {...(currentProjectState
                        ? { [currentProjectState]: true, animationState: currentProjectState }
                        : {})}
                />
                {previousProject && (
                    <PortfolioProject
                        {...previousProject}
                        key={previousProject.handle}
                        {...(previousProjectState
                            ? { [previousProjectState]: true, animationState: previousProjectState }
                            : {})}
                    />
                )}
            </div>
        )
    })
)`
    position: relative;

    @media ${mobileQuery} {
        ${({ project: { empty } }) => empty && `display: none;`}
    }
    opacity: 0;
    transform: translateY(5vh);
    transition: opacity 0.8s ease, transform 0.8s ease;

    &.animated--appeared {
        opacity: 1;
        transform: translateY(0px);
    }
`)

export const PortfolioLayout = ({ projects, ...props }) => {
    const [renderedProjects, setRenderedProjects] = useState(projects)
    const [groups, setGroups] = useState(buildGroups(renderedProjects))

    useEffect(() => {
        setRenderedProjects(projects)
        setGroups(buildGroups(projects))
    }, [projects])

    return (
        <PortfolioLayoutRoot>
            {groups.map((group) => {
                return <PortfolioGroup {...group.getLayout()} />
            })}
        </PortfolioLayoutRoot>
    )
}
