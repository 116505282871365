import React from 'react'
import styled, { css } from 'styled-components'

const fillParentMixin = ({ fillParent }) =>
    fillParent &&
    css`
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100%;
        width: 100%;

        ${({ cover }) => cover && `object-fit: cover;`}
    `

export const MP4VideoProvider = styled(
    ({
        cover,
        controls,
        autoplay,
        loop,
        fillParent,
        muted,
        file: { contentType, fileName, url },
        ...props
    }) => {
        /*
        const videoRef = useRef()

        const playVideo = () => {
            console.log({
                current: videoRef.current,
                paused: videoRef.current && videoRef.current.paused,
            })
            if (videoRef.current && videoRef.current.paused) {
                console.log('Play video')
                videoRef.current.muted = true
                videoRef.current.volume = 0
                videoRef.current.play()
            }
        }

        useEffect(() => {
            playVideo()

            setTimeout(playVideo, 1000)
        }, [videoRef])
        */

        const html = `
            <video
                ${controls ? 'controls="controls"' : 'onloadstart="this.controls = false;"'}
                ${
                    autoplay && false
                        ? 'autoplay="autoplay" playsinline="playsinline" muted="muted"'
                        : ''
                }
                ${loop ? 'loop="loop"' : ''}

                ${
                    autoplay
                        ? 'onloadeddata="this.muted = true; this.volume = 0; this.play();"'
                        : ''
                } 

                
            >
                <source src="${url}" type="${contentType}" />
                Your browser does not support the video tag.
            </video>
        `

        return (
            <div
                {...props}
                dangerouslySetInnerHTML={{
                    __html: html,
                }}
            />
        )
    }
)`
    &,
    video {
        width: 100%;
        ${fillParentMixin}
        line-height: 0;
    }
`
