import React from 'react'
import styled, { css } from 'styled-components'

import config from 'react-styled-flexboxgrid/lib/config'

/**
 * Grid component
 *
 * Built on react-styled-flexboxgrid, but could change if needed.
 *
 * Grid has been simplified to avoid the use of rows.
 */

import { Grid as BaseGrid, Col as BaseCol, Row as BaseRow } from 'react-styled-flexboxgrid'
import Container from './container'
import { onDesktop, onMobile } from '@src/utils/responsive'

export const Cell = styled((props) => <BaseCol xs={12} {...props} />)`
    margin-bottom: ${({ theme }) => theme.flexboxgrid.gutterWidth}rem;
`

export const Row = styled(BaseRow)`
    ${(p) =>
        p.customReverse &&
        (p.customReverse == 'mobile' ? onMobile : p.customReverse == 'desktop' ? onDesktop : css)`
    flex-direction: row-reverse;
    flex-wrap: wrap-reverse;
  `}
`

const Div = styled.div``

export const Grid = styled(
    ({
        className,
        children,
        multipleRows,

        // Row props
        reverse,
        start,
        center,
        end,
        top,
        middle,
        bottom,
        around,
        between,

        // Custom row props
        align,

        container,
    }) => {
        align = align ? align.toLowerCase() : false

        if (align == 'right') {
            end = 'xs'
        } else if (align == 'left') {
            start = 'xs'
        } else if (align == 'center') {
            center = 'xs'
        }

        const ContainerComponent = container ? Container : Div

        let customReverse = false
        if (reverse == 'mobile' || reverse == 'desktop') {
            customReverse = reverse
            reverse = undefined
        }

        return (
            <ContainerComponent className={className}>
                {multipleRows ? (
                    { children }
                ) : (
                    <Row
                        {...{
                            reverse,
                            customReverse,
                            start,
                            center,
                            end,
                            top,
                            middle,
                            bottom,
                            around,
                            between,
                        }}
                    >
                        {children}
                    </Row>
                )}
            </ContainerComponent>
        )
    }
)`
    margin-bottom: ${({ theme }) => theme.flexboxgrid.gutterWidth * -1}rem;
`
