import React from 'react'

import styled, { css } from 'styled-components'
import { typeBody20, typeHeading50, typeItalic } from './typography'
import Container from './container'
import { onMobile, onDesktop } from '@src/utils/responsive'
import ArrowTopIcon from '../icons/ArrowTopIcon'
import ArrowRightIcon from '../icons/ArrowRightIcon'

import { PageTransitionLink } from './transition-link'

import { get, take } from 'lodash-es'

import { scrollTo } from '@src/utils/'
import { mobileQuery, useResponsiveContext } from '../utils/responsive'
import Divider from './Divider'
import { Unveil, UnveilGroup } from '../utils/animated'
import moment from 'moment'

const Link = styled.a``

const FooterContainer = styled(Container)`
    position: relative;
`

const FooterInner = styled.footer``

const FooterTopContent = styled.div`
  
  ${typeHeading50}

  ${onMobile`
    flex-direction: column;
    margin-bottom: 8rem;
  `}

  ${onDesktop`
    display: flex;
    justify-content: space-between;

    margin-bottom: 18.2rem;
    padding-top: 0.6rem;
  `}
`

const FooterBottomContent = styled.div`
  ${typeBody20}

  @media ${mobileQuery} {
    flex-direction: column;
    margin-bottom: 4rem;
  }

  ${onDesktop`
    display: flex;
    justify-content: space-between;
    padding-bottom: 1.8rem;
  `}
`

const contentMixin = css`
    margin: 2.3rem 0;

    ${onDesktop`
    margin: 3.9rem 0;
  `}
`

const LeftContent = styled.div`
    ${contentMixin}

    & > * {
        margin-right: 3rem;

        ${onDesktop`
      margin-right: 7rem;
    `}
    }
`

const RightContent = styled.div`
    ${contentMixin}

    display: block;
    ${onMobile`
    display: none;
  `}
`

const GoToReelIcon = styled(ArrowTopIcon)`
    margin-left: 6.4rem;
    width: 2.6rem;
    height: auto;

    position: relative;
    transition: top 0.1s ease;
    top: 0;
`

const GoToReelLink = styled(Link)`
    &:hover {
        svg {
            top: -1rem;
        }
    }
`

const NextProjectIcon = styled(ArrowRightIcon)`
    margin-left: 6.4rem;
    width: 4rem;
    height: auto;

    position: relative;
    transition: left 0.1s ease;
    left: 0;
`

const NextProjectLink = styled(PageTransitionLink)`
    &:hover {
        svg {
            left: 1rem;
        }
    }
`

const Mail = styled((props) => (
    <a {...props} href={`mailto:${props.mail}`}>
        {props.mail}
    </a>
))`
    text-decoration: underline;

    ${onDesktop`
    &:hover {
      cursor: pointer;
      text-decoration: none;
    }
  `}
`

const HomepageFooterTopContent = ({ hasReel }) => {
    const { isMobile } = useResponsiveContext()
    return (
        <FooterTopContent>
            <LeftContent>
                <UnveilGroup>
                    <Unveil>Let's</Unveil> <Unveil>make</Unveil> <Unveil>something</Unveil>{' '}
                    {isMobile && <br />} <Unveil>hard</Unveil> <Unveil>to</Unveil>{' '}
                    <Unveil>replicate</Unveil>
                    <br />
                    <Unveil>
                        <Mail mail="hello@studioparadise.com" />
                    </Unveil>
                </UnveilGroup>
            </LeftContent>
            <RightContent>
                {hasReel && (
                    <GoToReelLink
                        href="#"
                        onClick={(e) => {
                            e.preventDefault()
                            scrollTo('#top')
                        }}
                    >
                        Watch Reel <GoToReelIcon />
                    </GoToReelLink>
                )}
            </RightContent>
        </FooterTopContent>
    )
}

const AboutFooterTopContent = () => {
    const { isMobile } = useResponsiveContext()
    return (
        <FooterTopContent>
            <LeftContent>
                <UnveilGroup>
                    <Unveil>Let's</Unveil> <Unveil>make</Unveil> <Unveil>something</Unveil>{' '}
                    {isMobile && <br />} <Unveil>hard</Unveil> <Unveil>to</Unveil>{' '}
                    <Unveil>replicate</Unveil>
                    <br />
                    {isMobile && <br />}
                    <Unveil>
                        <Mail mail="hello@studioparadise.com" />
                    </Unveil>
                </UnveilGroup>
            </LeftContent>
            <RightContent>
                <UnveilGroup>
                    <Unveil>Join</Unveil> <Unveil>our</Unveil> <Unveil>team</Unveil>
                    <br />
                    <Unveil>
                        <Mail mail="team@studioparadise.com" />
                    </Unveil>
                </UnveilGroup>
            </RightContent>
        </FooterTopContent>
    )
}

const ProjectsFooterTopContent = ({ nextEntryUrl }) => {
    const { isMobile } = useResponsiveContext()
    return (
        <FooterTopContent>
            <LeftContent>
                <UnveilGroup>
                    <Unveil>Let's</Unveil> <Unveil>make</Unveil> <Unveil>something</Unveil>{' '}
                    {isMobile && <br />} <Unveil>hard</Unveil> <Unveil>to</Unveil>{' '}
                    <Unveil>replicate</Unveil>
                    <br />
                    <Unveil>
                        <Mail mail="Hello@studioparadise.com" />
                    </Unveil>
                </UnveilGroup>
            </LeftContent>
            <RightContent>
                {nextEntryUrl && (
                    <NextProjectLink to={nextEntryUrl}>
                        Next Project <NextProjectIcon />
                    </NextProjectLink>
                )}
            </RightContent>
        </FooterTopContent>
    )
}

const EmptyFooterTopContent = () => <div></div>

export const Footer = ({ className, hasReel, location: { pathname }, nextEntryUrl }) => {
    const primaryPath = take(pathname.split('/'), 2).join('/')

    const contentMapping = {
        '/': HomepageFooterTopContent,
        '/homepage': HomepageFooterTopContent,
        '/about': AboutFooterTopContent,
        '/projects': ProjectsFooterTopContent,
    }

    const Content = get(contentMapping, primaryPath, EmptyFooterTopContent)

    return (
        <FooterContainer className={className} role="navigation">
            <FooterInner>
                <Content {...{ nextEntryUrl, hasReel }} />

                <FooterBottomContent>
                    <LeftContent>
                        <Link href="https://www.instagram.com/studioparadisenyc/">Instagram</Link>
                        <Link
                            href="https://www.linkedin.com/company/studio-paradise/about/"
                            target="_blank"
                        >
                            LinkedIn
                        </Link>
                        <Link href="https://www.behance.net/studioparadise" target="_blank">
                            Behance
                        </Link>
                    </LeftContent>

                    <RightContent>©{moment().year()} Studio Paradise</RightContent>
                </FooterBottomContent>
            </FooterInner>
        </FooterContainer>
    )
}

export default Footer
