import React, { useState } from 'react'

import styled, { css } from 'styled-components'

import { isEmpty, map } from 'lodash-es'

import { Body2 } from '../../components/Text'

export const PortfolioEmptyState = styled(
  ({ selectedCategories, ...props }) => {

    console.log({ selectedCategories })
    return (
      <div {...props}>
        <Body2>
          No projects were found
          {!isEmpty(selectedCategories)
            ? `
            for categor${selectedCategories.length == 1 ? 'y' : 'ies'} "${map(
                selectedCategories,
                'title'
              ).join('", "')}"
          `
            : ''}
          .
        </Body2>
      </div>
    )
  }
)`
  min-height: 60rem;
`
