import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'

import { onDesktop } from '@src/utils/responsive'

import Section from '../../components/section'

import { Heading2, Subtitle, Title } from '../../components/Text'

import { omit, includes, filter, isEmpty, map, intersection, difference, shuffle } from 'lodash-es'

import { PortfolioEmptyState } from './PortfolioEmptyState'
import { PortfolioLayout } from './PortfolioLayout'
import { TransitionLink } from 'gatsby-plugin-transition-link/components/TransitionLink'
import { typeHeading, typeHeading50 } from '../../components/typography'
import { Link } from 'gatsby'
import { desktopQuery, mobileQuery, useResponsiveContext } from '../../utils/responsive'
import { drop, take } from 'lodash'
import { em } from '../../theme'
import { animated } from '../../utils/animated'

const CategoryLink = styled((props) => {
    const LinkImplementation = props.onClick ? Link : TransitionLink

    return (
        <LinkImplementation {...omit(props, 'children', 'active')}>
            <span className="normal-text">{props.children}</span>
            <span className="hover-text">{props.children}</span>
        </LinkImplementation>
    )
})`
    position: relative;

    &,
    & > * {
        white-space: nowrap;
    }

    @media ${mobileQuery} {
        &:hover {
            text-decoration: inherit;
        }
    }

    .normal-text {
        @media ${mobileQuery} {
            ${({ active }) =>
                active
                    ? css`
                          ${typeHeading}
                          font-style: normal;
                          font-size: 0.9em;
                          line-height: ${em(21 / 14)};
                          text-decoration: underline;
                      `
                    : css``}
        }

        @media ${desktopQuery} {
            opacity: ${({ active }) => (!active ? 1 : 0)};
            /*transition: opacity 0.15s ease 0s;*/
        }
    }

    .hover-text {
        opacity: ${({ active }) => (active ? 1 : 0)};
        /*transition: opacity 0.15s ease;*/
        font-style: normal;

        position: absolute;
        bottom: 0rem;
        left: 50%;
        width: 100%;
        white-space: nowrap;
        transform: translate(-50%, 0);

        text-align: center;

        @media ${mobileQuery} {
            display: none;
        }

        @media ${desktopQuery} {
            ${typeHeading}
            font-size: 0.88em;
        }
    }

    @media ${desktopQuery} {
        &:hover {
            text-decoration: none;
        }

        &:hover {
            .normal-text {
                /*transition: opacity 0.15s ease;*/
                opacity: 0;
            }

            .hover-text {
                /*transition: opacity 0.15s ease 0s;*/
                opacity: 1;
            }
        }
    }
`

const Heading = styled(Heading2)`
    ${onDesktop`
    margin-top: ${({ theme }) => theme.margins.medium}rem;
    margin-bottom: 16.8rem;
  `}
`

const Category = styled(({ className, title, handle = '', active = false, onClick }) => (
    <span className={className}>
        <CategoryLink active={active} to={`/work/${handle ? handle + '/' : ''}`} onClick={onClick}>
            {title}
        </CategoryLink>
    </span>
))`
    &:after {
        display: inline;
        content: ', ';
    }

    &:last-child:after {
        display: none;
    }

    @media ${mobileQuery} {
        line-height: ${em(22 / 14)};
    }
`

const CategoriesColumn = styled.div`
    display: flex;
    flex-direction: column;
    width: 11.5rem;
`

const Categories = animated(styled(Subtitle)`
    @media ${desktopQuery} {
        max-width: 60%;
    }

    @media ${mobileQuery} {
        display: flex;
        font-size: 1.4rem;
    }

    opacity: 0;
    transform: translateY(5vh);
    transition: opacity 0.8s ease, transform 0.8s ease;

    &.animated--appeared {
        opacity: 1;
        transform: translateY(0px);
    }
`)

const PortfolioTitle = styled(Title)`
    @media ${desktopQuery} {
        max-width: 60%;
        margin-bottom: 12rem;
        font-size: 5rem;
        line-height: 5.8rem;
    }

    @media ${mobileQuery} {
        margin-top: 5.4rem;
        margin-bottom: 5.2rem;
        font-size: 2rem;
    }
`

const filterProjects = (projects, selectedCategories, visibleProjects = []) => {
    if (isEmpty(selectedCategories)) {
        return projects
    } else {
        const selectedCategoriesHandles = map(selectedCategories, 'handle')

        const oldVisibleProjectHandles = visibleProjects.map((p) => p.handle)
        const originalOrder = projects.map((p) => p.handle)
        const newOrder =
            oldVisibleProjectHandles.length == originalOrder.length
                ? shuffle(originalOrder)
                : difference(originalOrder, oldVisibleProjectHandles).concat(
                      oldVisibleProjectHandles
                  )

        const newVisibleProjects = isEmpty(selectedCategories)
            ? projects
            : projects.filter(
                  (project) =>
                      !isEmpty(
                          intersection(map(project.categories, 'handle'), selectedCategoriesHandles)
                      )
              )

        return newVisibleProjects.sort(
            (projectA, projectB) =>
                newOrder.indexOf(projectA.handle) - newOrder.indexOf(projectB.handle)
        )
    }
}

const PortfolioSection = ({
    className,
    title,
    projects = [],
    children,
    globals,
    marginTop,
    marginBottom,
    currentCategory = 'all',
    style,
    additiveFilteringBehavior = true,
}) => {
    const { isMobile } = useResponsiveContext()
    const isCategoryPresent = projects.reduce((presentCategories, project) => {
        if (!isEmpty(project.categories)) {
            project.categories.forEach(({ handle }) => (presentCategories[handle] = true))
        }
        return presentCategories
    }, {})

    const categories = (globals.projectCategories || []).filter((c) => isCategoryPresent[c.handle])

    const initialSelectedCategories = currentCategory == 'all' ? [] : [currentCategory]

    const [selectedCategories, setSelectedCategories] = useState(initialSelectedCategories)
    const [visibleProjects, setVisibleProjects] = useState(
        filterProjects(projects, initialSelectedCategories, projects)
    )

    const showAllProjects = () => {
        setSelectedCategories([])
        setVisibleProjects(projects)
    }

    const addSelectedCategory = (category) => {
        const newSelectedCategories = additiveFilteringBehavior
            ? [...selectedCategories, category]
            : [category]

        setSelectedCategories(newSelectedCategories)
        setVisibleProjects(filterProjects(projects, newSelectedCategories, visibleProjects))
    }

    const removeSelectedCategory = (category) => {
        const newSelectedCategories = filter(selectedCategories, (c) => c.handle != category.handle)
        setSelectedCategories(newSelectedCategories)
        setVisibleProjects(filterProjects(projects, newSelectedCategories, visibleProjects))
    }

    const isSelected = (category) => includes(map(selectedCategories, 'handle'), category.handle)

    useEffect(() => {
        document.addEventListener('work:category:select', (e) => {
            const { category: categoryHandle } = e.detail

            if (isCategoryPresent[categoryHandle]) {
                console.log('Category is present')
                const category = categories.find((c) => c.handle == categoryHandle)
                console.log('Category is ', category)
                addSelectedCategory(category)
            }
        })
    }, [])

    return (
        <Section
            id="work-section"
            className={className}
            margin
            {...{ marginTop, marginBottom }}
            style={style}
        >
            <Heading>
                <PortfolioTitle unveil>{title}</PortfolioTitle>
                <a
                    id="work"
                    style={{ position: 'relative', top: isMobile ? '-7rem' : '-18rem' }}
                ></a>
                {categories && categories.length >= 2 ? (
                    <Categories>
                        {isMobile ? (
                            <>
                                <CategoriesColumn>
                                    <Category
                                        title="All"
                                        active={isEmpty(selectedCategories)}
                                        onClick={(e) => {
                                            e.preventDefault()
                                            showAllProjects()
                                        }}
                                    />

                                    {take(categories, Math.floor(categories.length / 2)).map(
                                        (category, key) => (
                                            <Category
                                                key={key}
                                                {...category}
                                                active={isSelected(category)}
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    if (isSelected(category)) {
                                                        removeSelectedCategory(category)
                                                    } else {
                                                        addSelectedCategory(category)
                                                    }
                                                }}
                                            />
                                        )
                                    )}
                                </CategoriesColumn>
                                <CategoriesColumn>
                                    {drop(categories, Math.floor(categories.length / 2)).map(
                                        (category, key) => (
                                            <Category
                                                key={key}
                                                {...category}
                                                active={isSelected(category)}
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    if (isSelected(category)) {
                                                        removeSelectedCategory(category)
                                                    } else {
                                                        addSelectedCategory(category)
                                                    }
                                                }}
                                            />
                                        )
                                    )}
                                </CategoriesColumn>
                            </>
                        ) : (
                            <>
                                <Category
                                    title="All"
                                    active={isEmpty(selectedCategories)}
                                    onClick={(e) => {
                                        e.preventDefault()
                                        showAllProjects()
                                    }}
                                />
                                {categories.map((category, key) => (
                                    <Category
                                        key={key}
                                        {...category}
                                        active={isSelected(category)}
                                        onClick={(e) => {
                                            e.preventDefault()
                                            if (isSelected(category)) {
                                                removeSelectedCategory(category)
                                            } else {
                                                addSelectedCategory(category)
                                            }
                                        }}
                                    />
                                ))}
                            </>
                        )}
                    </Categories>
                ) : null}
            </Heading>

            {!isEmpty(visibleProjects) ? (
                <PortfolioLayout
                    projects={visibleProjects}
                    selectedCategories={selectedCategories}
                />
            ) : (
                <PortfolioEmptyState selectedCategories={selectedCategories} />
            )}
        </Section>
    )
}

export default styled(PortfolioSection)``
