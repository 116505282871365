import React from 'react'
import { Image } from './Image'
import { Video } from './Video'

export const Media = React.forwardRef(
    ({ file: { contentType = 'unknown', fileName, url, ...file } = {}, fluid, ...props }, ref) => {
        return contentType.includes('video') || contentType.includes('text/html') ? (
            <Video {...props} {...{ file: { contentType, fileName, url } }} ref={ref} />
        ) : (
            <Image {...props} {...{ fluid }} ref={ref} />
        )
    }
)
