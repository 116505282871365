import React from 'react'
import GatsbyImage from 'gatsby-image'
import styled, { css } from 'styled-components'
import { typeHeading50 } from './typography'

const ImagePlaceholder = styled(
    React.forwardRef(({ aspectRatio, ...props }, ref) => <div {...props} ref={ref} />)
)`
    position: relative;

    padding-bottom: ${({ aspectRatio }) => ((1 / aspectRatio) * 100).toFixed(2)}% !important;

    min-height: 0rem;

    background-color: rgba(0, 0, 0, 0.1);

    & > * {
        ${typeHeading50}
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: block;
        opacity: 0.75;
    }
`

export const Image = styled(
    React.forwardRef(({ fluid, title, aspectRatio, ...props }, ref) => {
        return fluid ? (
            <GatsbyImage
                ref={ref}
                {...props}
                title={title}
                fluid={{ ...fluid, aspectRatio: aspectRatio || fluid.aspectRatio }}
            />
        ) : (
            <ImagePlaceholder aspectRatio={aspectRatio} ref={ref}>
                <span>{title}</span>
            </ImagePlaceholder>
        )
    })
)`
    ${({ forceVisible }) =>
        forceVisible
            ? css`
                  & > img {
                      opacity: 0 !important;
                  }

                  picture > img {
                      transition: none !important;
                      opacity: 1 !important;
                  }
              `
            : css``}
`
