import React from 'react'
import styled, { css } from 'styled-components'
import Helmet from 'react-helmet'
import { get, identity, omit, throttle, lowerCase, bind, noop } from 'lodash-es'

import { MARGINS, marginTopMixin } from '../theme'

import { withTransitionState } from './Page/PageTransition'
import Footer from './footer'
import { NavbarRetainer } from './navbar'
import { Divider } from './Divider'

const debug = false ? bind(console.log, console, '[Page]') : noop

const Wrapper = styled.div`
    display: flex;
    min-height: 100vh;
    flex-direction: column;

    &:before {
        z-index: -1;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        content: '';
        display: block;
        pointer-events: none;
    }

    &.schema-black {
        background-color: transparent;
        &:before {
            background-color: var(--black);
        }
    }

    &.schema-white {
        background-color: transparent;
        &:before {
            background-color: var(--white);
        }
    }
`

const Content = styled.main`
    flex: 1;
    display: block;
    width: 100%;
`

const PageFooter = styled(Footer)`
    ${marginTopMixin}
`

export const Page = withTransitionState(
    ({
        id,
        navbarRetainer = false,
        title,
        siteTitle,
        location,
        schema = 'white',
        nextEntryUrl,
        transition,
        hasReel,
        footerMarginTop = MARGINS.none,
        render = true,
        ...props
    }) => {
        return (
            <Wrapper id={id} className={`page-wrapper schema-${lowerCase(schema)}`}>
                <Helmet title={`${[title, siteTitle].filter(identity).join(' | ')}`} />

                {render && (
                    <>
                        <Content>
                            {navbarRetainer && <NavbarRetainer />}
                            {props.children}
                        </Content>

                        <Divider alwaysVisible />
                        <PageFooter
                            location={location}
                            nextEntryUrl={nextEntryUrl}
                            marginTop={footerMarginTop}
                            hasReel={hasReel}
                        />
                    </>
                )}
            </Wrapper>
        )
    }
)

export default Page
